<template>
  <div>
    <c-table
      ref="grid2"
      class="q-mt-md"
      title="개선요청"
      :columns="grid2.columns"
      :gridHeight="gridHeight"
      :data="imprs"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editable&&!disabled"
      @linkClick="imprlinkClick"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!disabled" label="추가" :showLoading="false"  icon="add" @btnClicked="addImpr" />
        </q-btn-group>
      </template>
    </c-table>
    <c-table
      ref="grid1"
      title="즉시조치"
      :columns="grid.columns"
      :gridHeight="gridHeight"
      :data="imprImms"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editable&&!disabled"
      @linkClick="immlinkClick"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!disabled" label="추가" :showLoading="false"  icon="add" @btnClicked="addImprImm" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'outernal-impr',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiOuternalResultId: '',
      }),
    },
    outernal: {
      type: Object,
      default: () => ({
        saiOuternalResultId: '',  // 외부심사결과 일련번호
        plantCd: '',  // 사업장코드
        outernalResultName: '',  // 외부심사명
        auditStartDate: '',  // 심사시작일
        auditEndDate: '',  // 심사종료일
        period: [],
        auditTargetDeptCd: '',  // 심사대상부서
        auditDeptCd: '',  // 심사주관부서
        saiOuternalAuditAgency: null,  // 주관심사기관
        auditPurpose: '',  // 심사목적
        imprDerivedItems: '',  // 개선도출항목
        auditResult: null,  // 심사결과
        auditResultSummary: '',  // 심사결과요약
        auditTargetDeptReviewOpinion: '',  // 심사대상부서검토의견
        regUserId: '',
        chgUserId: '',
        auditTeams: [], // 심사팀
        deleteAuditTeams: [], // 심사팀 [삭제]
        imprs: [], // 개선
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '조치부서',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'link',
          },
          {
            name: 'actionContents',
            field: 'actionContents',
            label: '조치내용',
            align: 'left',
            style: 'width:400px',
            sortable: false,
          },
          {
            name: 'actionCompleteDate',
            field: 'actionCompleteDate',
            label: '조치완료일',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
        ],
      },
      grid2: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '조치부서',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'link',
          },
          {
            name: 'improveRequestContents',
            field: 'improveRequestContents',
            label: '요청내용',
            align: 'left',
            // style: 'width:400px',
            sortable: false,
          },
          {
            name: 'ibmStepName',
            field: 'ibmStepName',
            label: '진행상태',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'actionCompleteRequestDate',
            field: 'actionCompleteRequestDate',
            label: '조치완료 요청일',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
        ],
      },
      imprData: null,
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        closeCallback: null,
      },
    };
  },
  computed: {
    gridHeight() {
      let _height = this.contentHeight - 500;
      if (_height <= 300) {
        _height = 300
      }
      return String(_height) + 'px';
    },
    imprs() {
      return this.$_.filter(this.outernal.imprs, { ibmClassCd: 'IC00000001' })
    },
    imprImms() {
      return this.$_.filter(this.outernal.imprs, { ibmClassCd: 'IC00000005' })
    },
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.outernal.result.impr.url;
      // code setting
      // list setting
    },
    getImprs() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        saiOuternalResultId: this.outernal.saiOuternalResultId,
      }
      this.$http.request((_result) => {
        this.$set(this.outernal, 'imprs', _result.data)
      },);
    },
    addImprImm() {
      this.popupOptions.title = '즉시조치'; 
      this.popupOptions.param = {
        requestContents: this.outernal.outernalResultName,
        relationTableKey: this.outernal.saiOuternalResultId,
        ibmTaskTypeCd: 'ITT0000155',
        ibmTaskUnderTypeCd: 'ITTU000160',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    addImpr() {
      this.popupOptions.title = '개선요청';
      this.popupOptions.param = {
        requestContents: this.outernal.outernalResultName,
        relationTableKey: this.outernal.saiOuternalResultId,
        ibmTaskTypeCd: 'ITT0000155',
        ibmTaskUnderTypeCd: 'ITTU000160',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getImprs();
    },
    /* eslint-disable no-unused-vars */
    immlinkClick(row, col, index) {
      this.imprData = row;
      this.popupOptions.title = '즉시조치 상세'; 
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      this.popupOptions.param = {
        sopImprovementId: row.sopImprovementId,
      };
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImmDetailPopup;
    },
    closeImmDetailPopup(result, flag) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        if (flag !== 'delete') {
          this.$_.extend(this.imprData, result.col1)
        } else {
          this.outernal.imprs = this.$_.reject(this.outernal.imprs, { sopImprovementId: result.col1.sopImprovementId })
        }
      }
    },
    imprlinkClick(row, col, index) {
      this.imprData = row;
      this.popupOptions.title = '개선';
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.param = {
        sopImprovementId: row.sopImprovementId,
      };
      this.popupOptions.width = '85%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprDetailPopup;
    },
    closeImprDetailPopup(result, flag) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        if (flag !== 'delete') {
          this.$_.extend(this.imprData, result.col1)
        } else {
          this.outernal.imprs = this.$_.reject(this.outernal.imprs, { sopImprovementId: result.col1.sopImprovementId })
        }
      }
    },
  }
};
</script>
